
import Vue from "vue";
import IPeopleContactType from "@/lib/interfaces/people-contact-type";
import PeopleContactTypesList from "@/components/subviews/people-contact-types/people-contact-types-list.vue";
import PeopleContactTypesMaintenance from "@/components/subviews/people-contact-types/people-contact-types-maintenance.vue";

export default Vue.extend({
  name: "PeopleContactTypes",

  components: { PeopleContactTypesList, PeopleContactTypesMaintenance },

  data() {
    return {
      refresh: 0,

      currentTab: 0,

      peopleContactType: {} as IPeopleContactType,
    };
  },
});
