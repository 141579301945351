
import Api from "@/lib/api";
import { Rq } from "@/lib/amdt";
import Vue, { PropType } from "vue";
import { DataOptions } from "vuetify";
import IPeopleContactType from "@/lib/interfaces/people-contact-type";
import vAlertMessage from "@/components/layout/v-alert-message.vue";
import ATextField from "@/components/elements/a-text-field.vue";
import ABtn from "@/components/elements/a-btn.vue";
import ADataTable from "@/components/elements/a-data-table.vue";
import ACard from "@/components/elements/a-card.vue";
import ASelectStatus from "@/components/elements/a-select-status.vue";

export default Vue.extend({
  name: "PeopleContactTypesList",

  components: {
    vAlertMessage,
    ATextField,
    ABtn,
    ADataTable,
    ACard,
    ASelectStatus,
  },

  props: {
    peopleContactType: {
      type: Object as PropType<IPeopleContactType>,
      required: true,
    },

    forceRefresh: {
      type: Number as PropType<number>,
      required: true,
    },
  },

  data() {
    return {
      loading: false,
      ret_id: 1,
      ret_msg: "",

      total: 0,
      search: "",
      desativado: "0",

      options: {} as DataOptions,
      peopleContactTypes: new Array<IPeopleContactType>(),

      headers: [
        { text: "Descrição", value: "descricao" },
        { text: "Ordem", value: "corder" },
        { text: "Status", value: "cDesativado", sortable: false },
        { text: "Ações", value: "actions", sortable: false },
      ],
    };
  },

  watch: {
    forceRefresh() {
      this.getPeopleContactTypes().then();
    },

    options: {
      deep: true,
      handler() {
        this.getPeopleContactTypes().then();
      },
    },
  },

  computed: {
    cPeopleContactTypes(): Array<Record<string, unknown>> {
      return this.peopleContactTypes.map((peopleContactType) => ({
        ...peopleContactType,

        cDesativado: peopleContactType.desativado === "0" ? "Ativo" : "Inativo",
      }));
    },
  },

  methods: {
    async openPeopleContactType(id: string) {
      const peopleContactType = this.peopleContactTypes.find(
        (peopleContactType) => peopleContactType.id === id
      );

      this.$emit("update:people-contact-type", { ...peopleContactType });
    },

    async getPeopleContactTypes() {
      try {
        this.loading = true;
        this.ret_id = 1;
        this.ret_msg = "";

        const rq = new Rq("pessoas_contatos_tipos.read", {
          search: this.search,
          desativado: this.desativado,
          itemsPerPage: this.options.itemsPerPage.toString(),
          page: this.options.page.toString(),
          sortBy: this.options.sortBy[0] ?? "id",
          sortOrder: this.options.sortDesc[0] ? "DESC" : "ASC",
        });
        const rsp = await Api.request(rq);

        if (rsp.ret_id < 1) {
          this.ret_id = rsp.ret_id;
          this.ret_msg = rsp.ret_msg;
          return;
        }

        this.total = Number(rsp.params["total"]);
        this.options.itemsPerPage = Number(rsp.params["itemsPerPage"]);
        this.options.page = Number(rsp.params["page"]);

        this.peopleContactTypes =
          rsp.getTable("pessoas_contatos_tipos")?.getRowsObject() ?? [];
      } catch (error) {
        console.error(error);
      } finally {
        this.loading = false;
      }
    },
  },
});
